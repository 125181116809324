import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../CurricularClass52023/components/Sidebar2024';
import Archive_2022 from '../CurricularClass52023/Archive_2022';

const Class8Fieldtrip2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class8Fieldtrip/12.webp`;



    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Class 8 to Infantry Vama
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 8   DATE OF EVENT: 30 September 2024

                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School, Yeshwanthpur organised a field trip for students of Class 8 to Infantry Vama.
                                They were greeted by knowledgeable staff members who would guide them throughout the day.
                                The staff shared fascinating insights about the significance of Infantry Vama as a training centre.
                                This set the tone for an informative day ahead. The guided tour took students through training grounds,
                                where they witnessed rigorous drills and also took part in obstacle activites. 
                                The discipline and teamwork displayed left a lasting impression on the students, 
                                who admired the dedication required to serve in the military.
                                    <br />
                                    The field trip to Infantry Vama was a resounding success. Students returned to school with enriched perspectives, 
                                    memorable experiences and a sense of pride in their nation. This trip not only fulfilled educational objectives but also fostered a spirit of admiration and respect for the men and women in uniform. 
                                    The teachers noted the enthusiasm and engagement throughout the trip, indicating a profound impact on the students.
                                     This trip will undoubtedly be remembered as a pivotal moment in their educational journey.
                                     The trip not only broadened their knowledge about the armed forces but also encouraged discussions about patriotism and service to the country..
                                    <br />
                                    
                                    <br />

                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “OUR BODY IS A POWERFUL MACHINE. A STRONG MIND IS THE FUEL FOR IT.”
                                <br />
                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive_2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Class8Fieldtrip2024;